<template>
  <div>
    <h3 class="page-title"> تەكلىپنامە رەسىمى باشقۇرۇش</h3>
    <el-divider></el-divider>

    <el-row class="rtl-right">
      <el-button type="primary" plain icon="el-icon-circle-plus-outline" v-if="pageTags.create" @click="add">قوشۇش </el-button>
    </el-row>
    <div v-loading="loading">
      <el-row :gutter="10" style="direction:rtl;">
        <el-col :span="8" v-for="(item,index) in themeData" :key="index" style="float: right;">
          <el-card class="box-card">
            <div slot="header" class="clearfix" style="overflow:hidden;">
              <label style="float:right;">ھالىتى:</label>
              <span style="float:right;color: #67C23A;" v-if="item.status=='1'">ئوچۇق</span>
              <span style="float:right;color: #F56C6C;" v-if="item.status=='0'">ئېتىك</span>
              <el-button style="float:left;margin-right: 10px;" size="small" type="danger" @click="handleDelete(item)">ئۆچۈرۈش</el-button>
              <el-button style="float:left;" size="small" type="primary" @click="handleEdit(item)">تەھرىرلەش</el-button>
            </div>
            <img :src="item.image" class="theme-img" />
          </el-card>
        </el-col>
      </el-row>
    </div>

    <!-- el-dialog -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="40%"
      top="3vh"
      :before-close="handleClose">
      <div class="rtl-right form-content">
        <el-form label-width="120px" :model="ruleForm" ref="ruleForm">
          <el-form-item label="رەسىم" prop="image">
            <el-upload
              class="avatar-uploader"
              :action="imgActionUrl"
              name="image"
              :data="imageData"
              :headers="imageHeader"
              :on-error="handleImageError"
              :on-success="handleImageSuccess">
              <img v-if="ruleForm.image" :src="ruleForm.image" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="ھالەت" prop="status" class="radio-form">
            <el-radio v-model="ruleForm.status" label="1">ئوچۇق</el-radio>
            <el-radio v-model="ruleForm.status" label="0">ئېتىك</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="submitForm('ruleForm')">مۇقىملاش</el-button>
        <el-button type="warning" plain @click="resetForm('ruleForm')">ئەسلىگە قايتۇرۇش</el-button>
        <el-button type="danger" plain @click="closeDialog('ruleForm')">بىكار قىلىش</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<style lang="scss" scoped>
  .avatar-uploader{
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 320px;
    height: 480px;
    img{
      width: 320px;
      height: 480px;
    }
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 24px;
    color: #8c939d;
    width: 320px;
    height: 480px;
    line-height: 480px;
    text-align: center;
  }
  .box-card{
    text-align: center;
    margin-bottom: 10px;
    .theme-img{
      width: 320px;
      height: 480px;
      border: 1px solid #E4E7ED;
      border-radius: 4px;
    }
  }
</style>

<script>
  var self;
  import baseConfig from '../base.config.js'

  export default {
    activated: function() {
      self = this;
      self.getList();
      self.imgActionUrl = baseConfig.imgActionUrl;
    },
    data() {
      return {
        themeData: [],
        loading: false,
        pageTags:{},
        addDialog:true,
        dialogTitle:"",
        dialogVisible:false,
        editId:-1,
        ruleForm:{
          image:"",
          status:"1"
        },
        imgActionUrl:"",
        imageData:{
          "folder":"test"
        },
        imageHeader:{
          'Authorization': 'Bearer ' + localStorage.getItem("token"),
          'X-Requested-With': 'XMLHttpRequest'
        },
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self.$fetch("admin/theme").then(response => {
          if (response.status == 200) {
            self.themeData = response.data.data;
          } else{
            console.log(response.message);
          }
          if(JSON.parse(sessionStorage.getItem("curTags"))){
            self.pageTags = JSON.parse(sessionStorage.getItem("curTags"));
          }
          self.loading = false;
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.loading = false;
        });
      },
      add() {
        self.addDialog = true;
        self.dialogTitle = "قوشۇش";
        self.dialogVisible = true;
      },
      handleEdit(item){
        self.editId = item.id;
        self.ruleForm.image = item.image;
        self.ruleForm.status = item.status + '';
        self.addDialog = false;
        self.dialogTitle = "تەھرىرلەش";
        self.dialogVisible = true;
      },
      handleDelete(row) {
        self.$confirm("ئۆچۈرۈشنى مۇقۇملامسىز ؟", "ئەسكەرتىش", {
          confirmButtonText: "مۇقىملاش",
          cancelButtonText: "بىكار قىلىش",
          type: "warning"
        }).then(() => {
          self.$remove('admin/theme/'+row.id).then((response) => {
            console.log(response);
            if(response.status == 204){
              self.getList();
              self.$message({
                message: "ئۆچۈرۈش تاماملاندى",
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response);
            }
          }).catch(err => {
            console.log('××××××××××××××××');
            console.log(err);
          });

        }).catch(() => {
          self.$message({
            type: "info",
            message: "ئۆچۈرۈش بىكار قىلىندى"
          });
        });
      },
      submitForm(formName) {
        var myData = {
          image: self.ruleForm.image,
          status: self.ruleForm.status
        }

        if(self.addDialog){
          self.$post('admin/theme',myData).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response.data.message);
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }else{
          self.$put('admin/theme/'+self.editId,myData).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              self.$message({
                message: response.data.message,
                type: "warning",
                duration: 1500
              });
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }
      },
      resetForm(formName) {
        self.$refs[formName].resetFields();
        self.ruleForm = {
          image: "",
          status: "1"
        }
      },
      closeDialog(formName) {
        self.resetForm(formName);
        self.dialogVisible = false;
      },
      handleClose(done) {
        done();
        self.resetForm("ruleForm");
      },
      handleImageSuccess(res, file) {
        self.ruleForm.image = res.data.image;
      },
      handleImageError(err,file){
        var uploadErr = JSON.parse(err.toString().replace('Error:',''));
        self.$message({
          message: uploadErr.message,
          type: 'warning'
        });
      },
    }
  };
</script>
